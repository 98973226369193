import pino from 'pino';

import type { Logger } from './index';

class PinoLogger implements Logger {
  logger: pino.Logger;

  constructor() {
    const labels = {
      app_version_uuid: process.env.APP_VERSION_UUID ?? 'unknown',
      app_name: process.env.app_name ?? 'unknown',
      account_id: process.env.accounts_account_id ?? 'unknown',
      sdk_version: process.env.sdk_version ?? 'unknown',
      service_name: 'zapp_client',
    };

    const targets: pino.TransportTargetOptions[] = [
      {
        target: 'pino-pretty',
        options: {
          ignore: 'pid,hostname',
        },
      },
    ];

    const lokiHost = process.env.LOKI_HOST ?? 'http://localhost:3100';

    if (lokiHost) {
      targets.push({
        target: 'pino-loki',
        options: {
          host: lokiHost,
          labels,
        },
      });
    }

    const transport = pino.transport({ targets });
    this.logger = pino(transport).child(labels);
  }

  info(msg: string): void {
    this.logger.info(msg);
  }

  error(msg: string): void {
    this.logger.error(msg);
  }
}

export default PinoLogger;
